// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  PINATA_KEY: '3816585697981d38d50d',
  PINATA_SECRET: '4722d4967331aafb013d9dab2bc6e3534e654be9a1cbc8ce4aa4adaec3c13f27',
  BASE_URL: 'https://chocolate-magnificent-blackbird-410.mypinata.cloud/ipfs/',
  API_BASE_URL: 'https://stagingapi.realworld.fi/api/v1',
  API_BASE_URL_V2: 'https://stagingapi.realworld.fi/api/v2',
  API_BASE_URL_V3: 'https://stagingapi.realworld.fi/api/v3',
  SUPPORTED_CHAINS: ['137', '80002'],
  CHAIN_ID: '0x13882',
  WEB_SITE_URL: 'https://devapp.realworld.fi',
  WALLET_CONNECT_PROJECT_ID: "b6c201630414d0fd586cfc6add82cde7",
  BUG_ATLAS_API_KEY: 'CIEW9TJB9KBZE83I',
  BUG_ATLAS_SECRET_KEY: 'HEIZT5FYN5SER86Z',

  '80002': {
    TRANSH_URL: 'https://mumbai.polygonscan.com/tx/',
    EXPLORER_URL: 'https://mumbai.polygonscan.com/',
    FACTORY_ADDRESS: '0xf464ea4a4bf6ef44afaf226a9d5a366240fee3ef',
    ACCESS_CONTROL_TOKEN: '0x7Fc41CcB5f94ABE4E56949B3F06042780F7cDDab',
    PROVIDER: 'https://rpc-amoy.polygon.technology',
    CHAIN_NETWORK: '0x13882',
    CHAINID: '80002',
    CURRENCY_NAME: 'MATIC',
    CURRENCY_SYMBOL: 'MATIC',
    CHAIN_NAME: 'Polygon Test Network',
    FILE_PATH: '',
    BORROW_LEND_CONTRACT: '0x4D41C7BeF6F96Cc5fB67D010E45d0a8CC3d78bBC',
    EXCHANGE_CONTRACT: '0xBc17a089ff98b896c9F9C0F1058bdA24BF50d95A',
    LAZYMINT_CONTRACT: '0x692D575C4F4753ec9aB7858E27a28092944F2cb0',
    ERC721_CONTRACT: '0x18c9a0EFF3C0394440De38acB1C63ABf6ad5B542',
    PLATFORM_FEE: "0x9b69b1d02528f5073Cbe00e7DE86D42a8F85EC22"
  },
  '137': {
    TRANSH_URL: 'https://polygonscan.com/tx/',
    EXPLORER_URL: 'https://www.oklink.com/amoy',
    FACTORY_ADDRESS: '0xf50799A47ba6b835DA7Bbec874B8043534568dBe',
    ACCESS_CONTROL_TOKEN: '0x4D46A881B53D7CbaF31fb438f5F77A918c666998',
    PROVIDER: 'https://polygon-mainnet.infura.io/v3/dd42eb8e92fb4f55b21dc1070bb1d997',
    CHAIN_NETWORK: '0x89',
    CHAINID: 137,
    CURRENCY_NAME: 'MATIC',
    CURRENCY_SYMBOL: 'MATIC',
    CHAIN_NAME: 'Polygon',
    FILE_PATH: '',
    BORROW_LEND_CONTRACT: '0x1a82d7EC18D0b7f14fcA66CF348AF89293E2597E',
    EXCHANGE_CONTRACT: '0x27891344D21784069693E95Fba864220846f6468',
    LAZYMINT_CONTRACT: '0x374bC619D7Fd992cA96e9E09c978442ee5c37e2b',
    ERC721_CONTRACT: '0x3b9c6e3124421e26a2a1Fd685921E41E8ad96247',
    PLATFORM_FEE: "0x9b69b1d02528f5073Cbe00e7DE86D42a8F85EC22"
  },
  // Idle
  AUTO_LOGOUT_DURATION: 21600, //(six hours) In seconds; how long for inactive modal to appear
  AUTO_LOGOUT_TIMEOUT: 30, // In seconds; how long for user to act after inactive modal appears before being logged out.
};
